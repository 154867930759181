export const translatePreRegisterToSpanish = (preRegister) => {
    return {
        claveGrupoEmpresarial: preRegister.companyId,
        correo: preRegister.email,
        documentos: preRegister.documentos,
        grupoEmpresarial: preRegister.companyGroup,
        idAperturaCuenta: "00000000-0000-0000-0000-000000000000",
        idEstatus: 2,
        nombreEmpresa: preRegister.companyName,
        nombreRL: preRegister.legalRepName,
        primerApRL: preRegister.legalRepLastName,
        segundoApRL: preRegister.legalRepSecondLastName,
        razonSocial: preRegister.socialReason,
        rutaDocumentos: "",
        telefono: preRegister.phone
    }
}