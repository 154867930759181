import React, { useState, useEffect } from 'react';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';
import CompanyForm from './formulariosPartesPreRegistro/formularioEmpresa';
import LegalRepresentativeForm from './formulariosPartesPreRegistro/formularioRepresentante';
import ContactDetailsForm from './formulariosPartesPreRegistro/formularioDatoContacto';
import DocumentationFilesForm from './formulariosPartesPreRegistro/formularioDocumentacion';
import { ServiceGetDocumentsApertureAccount } from '../../../services/clients/serviceClientsAccounts';
import ResumenFormulario from './resumenFormulario';
import { ServicePostMerchantAdd } from '../../../services/merchants/serviceMerchant';
import { ApiPostApertureClient } from '../../../services/clients/ClientsAccounts';
import { AlertaFormulario } from './formulariosPartesPreRegistro/alertaFormulario';
import { translatePreRegisterToSpanish } from '../../../services/clients/TranslationsClients';
const FormularioPreRegistro = () => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ocurredError, setOcurredError] = useState(false);
    const steps = ['Empresa', 'Representante', 'Datos de contacto', 'Documentación', 'Finalizado'];
    const [isAllFormsComplete, setIsAllFormsComplete] = useState(false);
    const [responseNewPreRegister, setResponseNewPreRegister] = useState({
        type: "",
        message: "",
        httpStatusCode: 0,
        idPreRegister: "",
        idGroup: ""

    })

    const [companyData, setCompanyData] = useState({
        companyName: '',
        socialReason: '',
        country: '',
        city: '',
        street: '',
        postalCode: '',
        state: '',
        exteriorNumber: '',
        interiorNumber: '',
        colony: '',
        years: 0,
        companyGroup: false,
        companyId: ''
    });

    const [legalRepFormData, setLegalRepFormData] = useState({
        legalRepName: '',
        legalRepLastName: '',
        legalRepSecondLastName: '',
        CURP: '',
        RFC: ''
    });

    const [contactDetailsFormData, setContactDetailsFormData] = useState({
        contactName: '',
        phone: '',
        firstLastName: '',
        secondLastName: '',
        email: '',
        username: ''
    });

    const [fileFormData, setFileFormData] = useState({
        documentos: []
    });

    const [requestFormData, setRequestFormData] = useState({
        idAperturaCuenta: "",
        nombreEmpresa: "",
        razonSocial: "",
        nombreRL: "",
        primerApRL: "",
        segundoApRL: "",
        correo: "",
        telefono: "",
        rutaDocumentos: "",
        idEstatus: 2,
        grupoEmpresarial: false,
        claveGrupoEmpresarial: "",
        documentos: [],
    });

    const [filesForm, setFilesForm] = useState([]);
    const [isCompanyData, setIsCompanyData] = useState(false);
    const [isLegalRepFormValid, setIsLegalRepFormValid] = useState(false);
    const [isContactDetailsFormValid, setIsContactDetailsFormValid] = useState(false);
    const [isFilesFormValid, setIsFilesFormValid] = useState(false);

    const handleCompanyData = (newFormData, formValid) => {
        setCompanyData(newFormData);
        setIsCompanyData(formValid);
    };

    const handleLegalRepFormDataChange = (newFormData, formValid) => {
        setLegalRepFormData(newFormData);
        setIsLegalRepFormValid(formValid);
    };

    const handleContactDetailsFormDataChange = (newFormData, formValid) => {
        setContactDetailsFormData(newFormData);
        setIsContactDetailsFormValid(formValid);
    };

    const handleFileFormDataChange = (newFormData, formValid) => {
        if (newFormData) setFileFormData(newFormData);
        setIsFilesFormValid(formValid);
    };

    const handleSubmit = () => {
        if (isCompanyData && step === 0) {
            return true;
        } else if (isLegalRepFormValid && step === 1) {
            return true;
        } else if (isContactDetailsFormValid && step === 2) {
            return true;
        } else if (isFilesFormValid && step === 3) {
            //console.log("FileFormData", fileFormData);
            return true;
        } else {
            console.log('Form is invalid');
            return false;
        }
    };

    const handlePreRegister = async (request) => {
        const response = await ApiPostApertureClient(request, request.companyGroup);
        try {
            setLoading(true);
            if (!response.data) {
                setResponseNewPreRegister({
                    type: "error",
                    message: response.data.message,
                    httpStatusCode: response.data.httpStatusCode,
                    idPreRegister: "",
                    idGroup: ""
                })
                setOcurredError(true);
            } else if (response.data.httpStatusCode === 100) {
                setResponseNewPreRegister({
                    type: "info",
                    message: response.data.message,
                    httpStatusCode: response.data.httpStatusCode,
                    idPreRegister: "",
                    idGroup: ""
                })
                setOcurredError(false);
            } else {
                setResponseNewPreRegister({
                    type: "success",
                    message: response.data.message,
                    httpStatusCode: response.data.httpStatusCode,
                    idPreRegister: response.data.aperturaCuenta.idAperturaCuenta,
                    idGroup: response.data.aperturaCuenta.claveGrupoEmpresarial,
                })
                setOcurredError(false);
            }
        }

        catch (error) {
            console.error("Error al hacer la solicitud:", error.response);

        }
        finally {
            setLoading(false);
        }
    }

    const handleNext = () => {
        if (isAllFormsComplete) {
            setStep(4);
        }
        if (step < steps.length - 1) {
            if (handleSubmit()) {
                if (step === steps.length - 2) {
                    const formDataEnglish = {
                        ...companyData,
                        ...legalRepFormData,
                        ...contactDetailsFormData,
                        ...fileFormData,
                    };

                    const finalFormData = translatePreRegisterToSpanish(formDataEnglish);
                    setIsAllFormsComplete(true)
                    setRequestFormData(finalFormData);
                    //handlePreRegister(finalFormData);
                    //console.log(finalFormData);
                }

                setStep(step + 1);
            }
        }

    };

    useEffect(() => {
        if (step === 5) {
            //console.log(requestFormData);
            setCompanyData({
                ...companyData,
                companyGroup: false,
            });
            setIsAllFormsComplete(false);
            setResponseNewPreRegister({
                type: "success",
                message: "Formulario enviado con éxito",
                httpStatusCode: 200,
                idPreRegister: "0000000000000000",
                idGroup: "00001",
            })
            //handlePreRegister(requestFormData)
        }

    }, [step])

    const handlePrev = () => {
        if (step > 0) {
            setStep(step - 1);
        }
        if (step === 1) {
            setCompanyData({
                ...companyData,
                companyGroup: false,
            });
        }
        if (step === 5) {
            setStep(0)
        }
    };

    const fetchDocuments = async () => {
        try {
            const documentos = await ServiceGetDocumentsApertureAccount();
            const nuevosArchivos = documentos.map((documento) => ({
                idDocumento: documento.idDocumento,
                nombre: documento.documento,
                tipo: "file",
                required: documento.requerido,
                description: documento.descripcion,
            }));

            setFilesForm(nuevosArchivos);
        } catch (error) {
            setFilesForm([
                {
                    idDocumento: 1,
                    nombre: "Error en la carga de los documentos",
                    tipo: "file",
                    required: true,
                    description: "No se pudo cargar los documentos a enviar, por favor intente después...",
                },
            ]);
            console.error("Error fetching documents:", error);
        }
    };

    const styleConfig = {
        completedBgColor: "#32AC61",
        activeBgColor: "#1C1E3C"
    };

    const connectorStyleConfig = {
        activeColor: "#32AC61",
        completedColor: "#32AC61"
    };

    useEffect(() => {
        fetchDocuments();
    }, []);


    return (
        <div className='container mt-5'>
            <div className="d-flex flex-column align-items-center justify-content-around">
                <div className="d-flex flex-column">
                    <Stepper style={{ width: "80vw" }} connectorStateColors={true} activeStep={step} connectorStyleConfig={connectorStyleConfig} styleConfig={styleConfig}>
                        <Step label="Empresa" />
                        <Step label="Representante" />
                        <Step label="Datos de contacto" />
                        <Step label="Documentación" />
                        <Step label="Finalizado" />
                    </Stepper>
                </div>

                {step === 0 && <CompanyForm formData={companyData} onFormDataChange={handleCompanyData} />}
                {step === 1 && <LegalRepresentativeForm formData={legalRepFormData} onFormDataChange={handleLegalRepFormDataChange} />}
                {step === 2 && <ContactDetailsForm formData={contactDetailsFormData} onFormDataChange={handleContactDetailsFormDataChange} />}
                {step === 3 && <DocumentationFilesForm formData={fileFormData} onFormDataChange={handleFileFormDataChange} filesForm={filesForm} />}
                {step === 4 && (
                    <ResumenFormulario
                        companyData={companyData}
                        legalRepFormData={legalRepFormData}
                        contactDetailsFormData={contactDetailsFormData}
                        fileFormData={fileFormData}
                        onEditStep={setStep}
                    />
                )}

                {step === 5 && (
                    loading ? (
                        <Spinner variant="primary" animation="border" role="status"></Spinner>
                    ) : (
                        ocurredError ? (
                            <AlertaFormulario httpStatusCode={responseNewPreRegister.httpStatusCode} tipo="danger" titulo="Fallido" mensaje="Su información no ha sido posible enviarla, por favor intente más tarde o póngase en contacto" />
                        ) : (
                            <AlertaFormulario httpStatusCode={responseNewPreRegister.httpStatusCode} tipo="success" titulo="Éxito" mensaje="Su información ha sido enviada" idAperturaCuenta={responseNewPreRegister.idPreRegister} idGrupoEmpresarial={responseNewPreRegister.idGroup} />
                        )
                    )
                )}

                <div className="d-flex justify-content-around mt-4">

                    <Button className="mx-2" variant="secondary" onClick={handlePrev} disabled={step === 0}>
                        {step === 5 ? "Regresar" : "Anterior"}
                    </Button>
                    {step < 4 && (
                        <Button variant="primary" onClick={isAllFormsComplete ? () => { setStep(4);  } : handleNext} disabled={step === steps.length - 1} className="mx-2">
                            {isAllFormsComplete ? "Continuar" : "Siguiente"}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormularioPreRegistro;
