import axios from 'axios';
import { API_BASE_URL, API_BASE_TEST } from '../../helpers/GlobalVariables';

export const ApiPostApertureClient = (dataClient = {}, wantGroup = false, isDev = false) => {

    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL

    if (wantGroup) {
        dataClient["grupoEmpresarial"] = true;
    }
    //dataClient["rutaDocumentos"] = `MGRepositorio/${dataClient["nombreEmpresa"]}/Documentos/Apertura Cuenta`;
    delete dataClient[""]
    //console.log(dataClient);
    return axios.post(`${urlBase}/clients/AddAperturaCuenta`, dataClient)
        .then(response => {
            //(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}

export const ApiPostApertureClientWithOutDocuments = (dataClient = {}, wantGroup = false, isDev = false) => {
    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL
    if (wantGroup) {
        dataClient["grupoEmpresarial"] = true;
    }
    //dataClient["rutaDocumentos"] = `MGRepositorio/${dataClient["nombreEmpresa"]}/Documentos/Apertura Cuenta`;
    delete dataClient[""]
    //delete dataClient["documentos"]
    //console.log(dataClient);
    return axios.post(`${urlBase}/clients/AddAperturaCuentaSinDocumentos`, dataClient)
        .then(response => {
            //console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}

export const ApiGetDocumentosAperturaCuenta = ( isDev = false, queryParameters = {
    proceso: "Alta de empresas",
    activo: true
}) => {
    console.log(isDev);
    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL

    return axios.get(`${urlBase}/clients/GetDocumentosAperturaCuenta`, { params: queryParameters })
        .then(response => {
            //console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}


export const ApiSendEmailForANewMerchantAccount = (queryParameters = {
    idAperturaCuenta: "",
    claveGrupoEmpresarial: ""
}, isDev = false) => {

    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL

    // Crear un string de query params a partir del objeto queryParameters
    const queryParamsString = new URLSearchParams(queryParameters).toString();

    // Hacer la solicitud con los query params en la URL
    return axios.post(`${urlBase}/clients/EnviarCorreoAperturaCuenta?${queryParamsString}`)
        .then(response => {
            //console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}

export const ApiGetDocumentosAperturaCuentaRejected = (isDev = false, queryParameters = {
    idAperturaCuenta: "",
}) => {
    console.log(isDev);
    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL

    const queryParamsString = new URLSearchParams(queryParameters).toString();

    return axios.get(`${urlBase}/clients/GetDocumentosRechazadosxEmpresa?${queryParamsString}`)
        .then(response => {
            //console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}

export const ApiGetDocumentosAperturaCuentaMissing = (queryParameters = {
    idAperturaCuenta: "",
}, isDev = false ) => {

    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL

    const queryParamsString = new URLSearchParams(queryParameters).toString();

    return axios.get(`${urlBase}/clients/GetDocumentosFaltantesxEmpresa?${queryParamsString}`)
        .then(response => {
            //console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}

export const ApiUpdateDocument = (isDev = false, documentToUpdate = {
    idAperturaCuenta: '',
    documento: {
        idDocumento: 0,
        nombreDocumento: '',
        docBase64: '',
    }
} ) => {

    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL
    console.log();
    delete documentToUpdate[""];
    return axios.post(`${urlBase}/clients/UpdActualizaDocumento`, documentToUpdate)
        .then(response => {
            // console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}

export const ApiGetAperturaCuentaContinue = (queryParameters = {
    idAperturaCuenta: "",
}, isDev = false) => {
    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL
    const queryParamsString = new URLSearchParams(queryParameters).toString();
    return axios.get(`${urlBase}/clients/GetAperturaCuentaContinue?${queryParamsString}`)
        .then(response => {
            //(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}

export const ApiGetDatosEmpresaxFolio = (queryParameters = {
    idAperturaCuenta: "",
}, isDev = false) => {
    const urlBase = isDev ? API_BASE_TEST : API_BASE_URL
    const queryParamsString = new URLSearchParams(queryParameters).toString();
    return axios.get(`${urlBase}/clients/GetDatosEmpresaxFolio?${queryParamsString}`)
        .then(response => {
            //console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}