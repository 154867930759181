import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const ContactDetailsForm = ({ formData, onFormDataChange }) => {
  const [errors, setErrors] = useState({});

  const [isFirstTimeLoaded, setFirstTimeLoaded] = useState(1);

  useEffect(() => {
    isFirstTimeLoaded === 1 ? setFirstTimeLoaded(2) : validateForm();
  }, [formData]);

  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    if (!formData.contactName.trim()) {
      newErrors.contactName = 'El nombre de contacto es requerido';
      valid = false;
    }
    if (!formData.firstLastName.trim()) {
      newErrors.firstLastName = 'El apellido paterno del contacto es requerido';
      valid = false;
    }
    if (!formData.secondLastName.trim()) {
      newErrors.secondLastName = 'El apellido materno del contacto es requerido';
      valid = false;
    }
    if (!formData.phone.trim()) {
      newErrors.phone = 'El número de teléfono es requerido';
      valid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = 'El correo electrónico es requerido';
      valid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Formato del correo electrónico inválido, por favor verifique.';
      valid = false;
    }

    setErrors(newErrors);
    onFormDataChange(formData, valid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'email') {
      onFormDataChange({
        ...formData,
        [name]: value,
        username: value, // Asignar el email al username
      });
    } else if (name === 'phone') {
      const newValue = value.replace(/\D/g, '').slice(0, 10); // Permitir solo números y limitar a 10 dígitos
      onFormDataChange({ ...formData, [name]: newValue });
    } else {
      onFormDataChange({
        ...formData,
        [name]: value,
      });
    }
  };

  return (
    <Form noValidate>
      <Row>
        <Col className='my-3' md={12}>
          <Form.Group controlId="contactName">
            <Form.Label className="ralewayRegular">Nombre de contacto <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="contactName"
              value={formData.contactName}
              onChange={handleChange}
              isInvalid={!!errors.contactName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.contactName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className='my-3' md={12}>
          <Form.Group controlId="firstLastName">
            <Form.Label className="ralewayRegular">Apellido paterno <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="firstLastName"
              value={formData.firstLastName}
              onChange={handleChange}
              isInvalid={!!errors.firstLastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstLastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className='my-3' md={12}>
          <Form.Group controlId="secondLastName">
            <Form.Label className="ralewayRegular">Apellido materno <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="secondLastName"
              value={formData.secondLastName}
              onChange={handleChange}
              isInvalid={!!errors.secondLastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.secondLastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className='my-3' md={12}>
          <Form.Group controlId="phone">
            <Form.Label className="ralewayRegular">Teléfono <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              isInvalid={!!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className='my-3' md={12}>
          <Form.Group controlId="email">
            <Form.Label className="ralewayRegular">Correo electrónico <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className='my-3' md={12}>
          <Form.Group controlId="username">
            <Form.Label className="ralewayRegular">Nombre de usuario</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactDetailsForm;
