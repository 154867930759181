import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NominaPage } from './pages/nomina'
import { CreditoMG } from './pages/credito/creditoMG'
import { PrestamoMG } from './pages/credito/prestamoMG'
import { NotFoundPage } from './pages/notFoundPage'
import { ArrendamientoPage } from './pages/arrendamiento'
import { SobreNosotrosPage } from './pages/sobreNosotros';
import { Privacidad } from './pages/privacidad';
import { DatosUNE } from './pages/datosUNE';
import { Cobranza } from './pages/cobranza';
import { Legales } from './pages/legales';
import { FormMerchant } from './pages/formularioMerchant';
import { UnsubscribePage } from './pages/unsubscribe';
import { FormPreAltaMerchant } from './pages/formularioPreAltaMerchant';
import { RegistroPage } from './pages/registro/registroPage';
import { LegalesPage } from './pages/legales/legalesPage';
import { FormPreAltaSubirArchivos } from './pages/formularioSubirArchivos';
import { FormAltaMerchant } from './pages/formularioAltaMerchant';
import { PromotionPage } from './pages/promocion';
import { BenemaxPage } from './pages/benemax ';
import { PreRegistroPage } from './pages/formularioPreRegistro';
import BodyContent from './Body';

export const Rutas = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<BodyContent />} />
				<Route path="/nomina" element={<NominaPage />} />
				<Route path="/sobre_nosotros" element={<SobreNosotrosPage/>}/>
				<Route path="/credito/credito_MG" element={<CreditoMG />} />
				<Route path="/credito/prestamo_MG" element={<PrestamoMG />} />
				<Route path="/arrendamiento" element={<ArrendamientoPage />} />
				<Route path="/aviso_privacidad" element={<Privacidad />} />
				<Route path="/datosUNE" element={<DatosUNE />} />
				<Route path="/cobranza" element={<Cobranza />} />
				<Route path="/legales" element={<LegalesPage />} />
				<Route path="/formulario_merchant" element={<FormMerchant />} />
				<Route path="/on_boarding" element={<FormMerchant />} />
				<Route path="/on_boarding_subsanar" element={<FormPreAltaMerchant />} />
				<Route path="/formulario_alta" element={<FormAltaMerchant />} />
				<Route path="/unsubscribe" element={<UnsubscribePage />} />
				<Route path="/formulario_pre_alta" element={<FormPreAltaSubirArchivos />} />
				<Route path="/formulario_subsanar_documentos" element={< FormPreAltaMerchant />} />
				<Route path="/registro" element={<RegistroPage />} />
				<Route path="/servicios_legales" element={<Legales />} />
				<Route path="/promocion" element={<PromotionPage />} />
				<Route path="/benemax" element={<BenemaxPage/>}/>
				<Route path="/pre_registro" element={<PreRegistroPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</Router>
	);
}
