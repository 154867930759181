import { Button, Alert, Spinner } from 'react-bootstrap';

export const AlertaFormulario = ({ idAperturaCuenta, idGrupoEmpresarial, tipo, titulo, mensaje, httpStatusCode }) => {
    return (
        <h3>
            <Alert variant={tipo}>
                {titulo} <br /> {mensaje}
                {httpStatusCode === 100 ? <>
                    <br /> Su información si se ha enviado, pero no se pudo enviar el correo a MoneyGiver, por favor, contáctesé por medio de WhatsApp.
                </> : null}
                {idAperturaCuenta && <>
                    <br />Aquí esta su folio de la apertura : {idAperturaCuenta}
                    <br />Y su folio de grupo: {idGrupoEmpresarial} <Button size="sm" onClick={() => {
                        navigator.clipboard.writeText(`Folio de apertura: ${idAperturaCuenta}\nFolio de grupo: ${idGrupoEmpresarial}`);
                    }} variant="outline-success"><i className="bi-copy"></i>
                    </Button>
                </>}
            </Alert>
        </h3>
    )
}